import { Tooltip, Menu, MenuItem, ListItemIcon, Stack, Divider, ToggleButtonGroup, ToggleButton, Chip } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import AccountCircle from '@mui/icons-material/AccountCircle'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ChatIcon from '@mui/icons-material/Chat'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUser } from '../interface/IUser'
import { DarkMode, Language, LightMode, Logout, Settings } from '@mui/icons-material'
import { setTheme } from '../source/slice/ThemeSlice'
import { useMsal } from '@azure/msal-react'
import mbtiLogo from '../img/mbti-logo.png'
import logo from '../img/logo.png'

function Navigation() {
    //const theme = useTheme()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const user: IUser = useSelector((state: any) => state.user?.value)
    const theme = useSelector((state: any) => state.theme?.value)
    const { instance } = useMsal()

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const changeTheme = (color: string) => {
        dispatch(setTheme(color))
    }

    const logout = async () => {
        await instance.logoutRedirect()
        await instance.logoutPopup()
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={(theme) => ({
                    backgroundColor: theme.palette.common.black,
                    backgroundImage: 'none',
                    boxShadow: 'none',
                })}
            >
                <Toolbar>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }  }}>
                        <img src={mbtiLogo} alt="MBTI-Logo" style={{ marginRight: '10px', height: 50 }} />
                    </Box>

                    <img src={logo} alt="Logo" style={{ marginRight: '10px', width: 32, height: 32 }}/>

                    <Typography variant="h5" component="div" sx={{ fontFamily: 'MB Corpo A Title', flexGrow: 1 }}>
                        Time Tracking
                    </Typography>
                    <Chip label="Confidential" color="error" variant="outlined" />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <Tooltip title={t('navigation.depot')}>
                            <IconButton size="large" color="inherit">
                                <Badge>
                                    <MoreTimeIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip> */}

                        <Tooltip title={t('navigation.helpcenter')}>
                            <IconButton size="large" color="inherit">
                                <Badge>
                                    <QuestionMarkIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t('navigation.support')}>
                            <IconButton size="large" color="inherit">
                                <Badge>
                                    <ChatIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        {/*<Tooltip title={t('navigation.reports')}>
                            <IconButton size="large" color="inherit">
                                <Badge>
                                    <AssessmentIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t('navigation.settings')}>
                            <IconButton size="large" color="inherit">
                                <Badge>
                                    <SettingsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                         <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={'NEW'} color="error">
                                <NotificationsIcon
                                    sx={{
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                />
                            </Badge>
                        </IconButton> */}
                        <IconButton size="large" edge="end" aria-haspopup="true" color="inherit" aria-controls={open ? 'basic-menu' : undefined} aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <Stack direction="row" alignItems="center" gap={1} px={2} paddingTop={1}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {user?.worker?.mserp_name}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1} px={2}>
                                <Typography variant="body2" sx={{ color: 'grey' }}>
                                    {user?.resource?.mserp_resourceid}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1} px={2} paddingBottom={1}>
                                <Typography variant="body2" sx={{ color: 'grey' }}>
                                    {user.userid}
                                </Typography>
                            </Stack>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                            <Stack alignItems="center" px={2} py={1}>
                                <ToggleButtonGroup exclusive onChange={(e, val: string) => changeLanguage(val)} aria-label="text alignment">
                                    <ToggleButton value="de" aria-label="left aligned" selected={i18n.language === 'de'} style={{ width: '108px', gap: '6px' }}>
                                        <Language /> Deutsch
                                    </ToggleButton>
                                    <ToggleButton value="en" aria-label="right aligned" selected={i18n.language === 'en'} style={{ width: '108px', gap: '6px' }}>
                                        <Language /> English
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack alignItems="center" px={2} py={1}>
                                <ToggleButtonGroup exclusive onChange={(e, val: string) => changeTheme(val)} aria-label="text 2">
                                    <ToggleButton value="light" aria-label="right aligned" selected={theme === 'light'} style={{ width: '150px', gap: '6px' }}>
                                        <LightMode /> {t('navigation.lightMode')}
                                    </ToggleButton>
                                    <ToggleButton value="dark" aria-label="left aligned" selected={theme === 'dark'} style={{ width: '150px', gap: '6px' }}>
                                        <DarkMode /> {t('navigation.darkMode')}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                            {/* <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                {t('navigation.settings')}
                            </MenuItem>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} /> */}
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                {t('navigation.logout')}
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navigation

import axios, { AxiosRequestConfig } from 'axios'
import { Box, Button, Paper, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Tooltip, TooltipProps, tooltipClasses, styled, Skeleton } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ITimecard } from '../interface/ISelectedDate'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { setAlert } from '../source/slice/AlertSlice'
import { format } from 'date-fns'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ButtonGroup } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { UpdateStoreHelper } from '../source/StoreHelper'
import { ErrorStatusCode } from '../interface/ErrorStatusCode'
import { IJournal } from '../interface/IJournal'
import { UpdateStoreHelperJournal } from '../source/StoreHelperJournal'
import { IUser } from '../interface/IUser'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const Footer = () => {
    const { t } = useTranslation()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const selectedJournal: IJournal = useSelector((state: any) => state.journal?.value)
    const user : IUser = useSelector((state:any) => state.user?.value)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [openDialog, setOpenDialog] = useState(false)
    const popoverRef = React.useRef<HTMLDivElement | null>(null)
    const [dialogContentText, setDialogContentText] = useState(t('footer.releaseWithIllnessConfirmation'))

    const isInternal: boolean = (user?.worker?.mserp_employmentcategory && ((user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_INTERNAL) || (user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT))) ? true : false
    const isStudent: boolean = (user?.worker?.mserp_employmentcategory && (user?.worker?.mserp_employmentcategory === process.env.REACT_APP_USER_STUDENT)) ? true : false
    const isANU: boolean = (user?.worker?.mserp_employmentcategory && (user?.worker?.mserp_employmentcategory === 'ANÜ')) ? true : false
    const calendarBlocked: boolean = ((selectedDate?.header?.mserp_timeprofileid && (selectedDate?.header?.mserp_timeprofileid === 'BLOCK')) || (selectedDate?.header?.specialDayId && (selectedDate?.header?.specialDayId === 'REDUCE'))) ? true : false

    const [backendModalOpen, setBackendModalOpen] = useState(false)
    const [backendModalMessage, setBackendModalMessage] = useState('')

    type ErrorCode = 101 | 102 | 103 | 9000 | 9001 | 9002 | 9003 | 9004 | 9005

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.saveSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.saveError'),
        type: 'error',
    }

    const customSnackbarMessageReleaseSuccess: ICustomSnackbar = {
        message: t('zdeheader.releaseSuccess'),
        type: 'success',
    }
    const customSnackbarMessageReleaseError: ICustomSnackbar = {
        message: t('zdeheader.releaseError'),
        type: 'error',
    }

    const handleClick = () => {
        if (user?.worker?.mserp_usetimecard) {
            postTimecard()
        } else {
            postJournal()
        }
    }

    const handleReleaseClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const simulateBackendResponse = (code: ErrorCode) => {
        const errorMessages = {
            9000: t('footer.backendErrors.maximumtime') + ' ' + t('footer.backendErrors.missingComment'),
            9001: t('footer.backendErrors.breaktime') + ' ' + t('footer.backendErrors.missingComment'),
            9002: t('footer.backendErrors.dayafter') + ' ' + t('footer.backendErrors.missingComment'),
            9003: t('footer.backendErrors.daybefore') + ' ' + t('footer.backendErrors.missingComment'),
            9004: t('footer.backendErrors.unknownError'),
            9005: t('footer.backendErrors.sundaywork') + ' ' + t('footer.backendErrors.missingComment'),
            9101: t('footer.backendErrors.toomuchdistributed'),
            9102: t('footer.backendErrors.toolessdistributed'),
            //9103: t('footer.backendErrors.missingproject'),
            9104: t('footer.backendErrors.missingactivity'),
            9105: t('footer.backendErrors.missingorder'),
            9106: t('footer.backendErrors.missingfromtoheader'),
            101: t('footer.backendErrors.missingFields'),
            102: t('footer.backendErrors.missingComment'),
            103: t('footer.backendErrors.unknownError'),
            // to be extended (more codes)
        }

        const simulateError = true

        if (simulateError) {
            const errorMessage = errorMessages[code] || t('footer.backendErrors.unknownError')
            setBackendModalMessage(`${errorMessage}`)
        } else {
            setBackendModalMessage(t('footer.backendResponse'))
        }
        setBackendModalOpen(true)
    }

    const handleReleaseWithIllness = () => {
        // to be extended (backend API call)
        setOpenDialog(true)
        setAnchorEl(null)
    }

    const handleDialogClose = (confirm: boolean) => {
        if (confirm) {
            releaseTimecardWithIllness()
            //simulateBackendResponse(101) // error message code can be changed
            setOpenDialog(false)
        } else {
            setOpenDialog(false)
        }
    }

    const accessTokenRequest = {
        scopes: ['openid', 'profile', 'email', 'user.read'],
        account: accounts[0],
    }

    const postTimecard = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const postJournal = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Journal`, JSON.stringify(selectedJournal), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                            if (selectedJournal.header?.date) {
                                updateJournal(selectedJournal.header?.date)
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const updateDate = (date: string) => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                UpdateStoreHelper(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
            })
    }

    const updateJournal = (date: string) => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                UpdateStoreHelperJournal(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
            })
    }

    const releaseTimecard = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecard`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const releaseTimecardWithIllness = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecardWithIllness`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const releaseJournal = () => {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.idToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedJournal.header?.date ? format(selectedJournal.header?.date, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseJournal`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedJournal.header?.date) {
                                updateJournal(selectedJournal.header?.date)
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response?.data
                        if (err?.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err?.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                    })
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenRedirect(accessTokenRequest)
                }
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const TextCenterTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            textAlign: 'center',
            lineHeight: '1.5em',
            padding: '8px 12px',
        }
    })

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100vw',
            }}
        >
            <Paper sx={{ bottom: 0 }}>
                {/* <Tooltip title={t('footer.totalamountdescription')}>
                <span>
                    {t('footer.totalamount', {
                        from: format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.'),
                        to: format(new Date(), 'dd.MM.yyyy'),
                        amount: 40,
                    })}
                </span>
            </Tooltip> */}
                <Box sx={{ p: 1, py: { xs: '12px', md: 1 }, display: 'flex', gap: 1, justifyContent: { xs: 'space-between', md: 'flex-end' }, alignItems: 'center', flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                    {(isInternal || isANU) && (selectedDate.totalHours !== undefined ? (
                        <Box display='flex' alignItems='center' sx={{ gap: {xs: '4px', sm: '10px'}, marginRight: { xs: '0', md: '12px' }, fontSize: { xs: '0.875rem', sm: '1rem' }, '@media (max-width: 359px)': { fontSize: '0.8rem' } }}>
                            <TextCenterTooltip title={t('footer.totalamountdescription')}>
                                <Box display='flex' alignItems='center' height='fit-content'>
                                    <span>{t('footer.totalamount', {
                                        from: format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.'),
                                        to: format(new Date(), 'dd.MM.yyyy'),
                                        amount: selectedDate.totalHours.toFixed(2)
                                    })}</span>
                                </Box>
                            </TextCenterTooltip>
                            {isInternal && (
                                <Box display='flex'>
                                    {!isStudent && (
                                        <>
                                            {(selectedDate.totalHours < -60) && (
                                                <TextCenterTooltip title={t('footer.redLightMinus')}>
                                                    <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            {(selectedDate.totalHours >= -60 && selectedDate.totalHours < -40) && (
                                                <TextCenterTooltip title={t('footer.yellowLightMinus')}>
                                                    <WarningIcon color="warning" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            {(selectedDate.totalHours >= -40 && selectedDate.totalHours <= 120) && (
                                                <TextCenterTooltip title={t('footer.greenLightInfo')}>
                                                    <CheckCircleIcon color="success" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            {(selectedDate.totalHours > 120 && selectedDate.totalHours <= 160) && (
                                                <TextCenterTooltip title={t('footer.yellowLightPlus')} sx={{ textWrap: 'balance', textAlign: 'center' }}>
                                                    <WarningIcon color="warning" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            {(selectedDate.totalHours > 160) && (
                                                <TextCenterTooltip title={t('footer.redLightPlus')}>
                                                    <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                        </>
                                    )}
                                    {isStudent && (
                                        <>
                                            { (selectedDate.totalHours < -20) && (
                                                <TextCenterTooltip title={t('footer.redLightMinus')}>
                                                    <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            { (selectedDate.totalHours >= -20 && selectedDate.totalHours <= 40) && (
                                                <TextCenterTooltip title={t('footer.greenLightInfo')}>
                                                    <CheckCircleIcon color="success" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                            { (selectedDate.totalHours > 40) && (
                                                <TextCenterTooltip title={t('footer.redLightPlus')}>
                                                    <ErrorIcon color="error" sx={{ width: { xs: '0.9em', sm: '1em' }, height: { xs: '0.9em', sm: '1em' } }} /> 
                                                </TextCenterTooltip>
                                            )}
                                        </>
                                    )}
                                </Box>
                            )}
                        </Box>
                    ) : <Skeleton variant="text" sx={{ maxWidth: "calc(100% - 32px)", width: 300 }} />)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant="outlined" size="medium" onClick={handleClick} sx={{ ml: { xs: 0, md: 1 }, mr: { xs: '6px', sm: 1 } }} startIcon={<SaveIcon sx={{ '@media (max-width: 359px)': { display: 'none' } }}/>} disabled={(selectedDate?.header?.isTransferred || calendarBlocked || selectedJournal?.header?.mserp_posted)}>
                            {t('footer.save')}
                        </Button>
                        <div ref={popoverRef} style={{ position: 'absolute', top: 0, left: 0 }}></div>
                        <ButtonGroup variant="contained" sx={{ marginRight: { xs: '0', md: '24px' } }}>
                            <Button size="medium" onClick={user?.worker?.mserp_usetimecard ? releaseTimecard : releaseJournal} startIcon={<CheckIcon sx={{ '@media (max-width: 359px)': { display: 'none' } }}/>} disabled={(selectedDate?.header?.isTransferred || calendarBlocked || selectedJournal?.header?.mserp_posted)}>
                                {t('footer.release')}
                            </Button>
                            {isInternal && (
                                <Button size="small" onClick={handleReleaseClick} disabled={(selectedDate?.header?.isTransferred || calendarBlocked)}>
                                    <ArrowDropDownIcon />
                                </Button>
                            )}
                        </ButtonGroup>
                    </Box>
                    {isInternal && (
                        <Menu
                            id="release-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <MenuItem onClick={handleReleaseWithIllness}>{t('footer.releaseWithIllness')}</MenuItem>
                        </Menu>
                    )}
                </Box>
            </Paper>

            <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
                <DialogTitle>{t('footer.releaseWithIllness')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('footer.releaseWithIllnessConfirmation')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        {t('footer.release')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={backendModalOpen} onClose={() => setBackendModalOpen(false)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <ErrorOutlineIcon color="error" sx={{ marginRight: '8px' }} />
                        <Typography variant="h6" component="div">
                            {t('footer.error')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{backendModalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBackendModalOpen(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Footer
